// client/src/App.js

import React,{useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css'
import 'react-tooltip/dist/react-tooltip.css'
import RoleAuth from "./Components/RoleAuth";
import SidebarLayout from "./Components/SidebarLayout";
import Unauthorized from "./Components/Unauthorized";
import Admin from "./Pages/Admin/Admin";

import Business from "./Pages/Business/Business";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Devices from "./Pages/Devices/Devices";
import Login from "./Pages/Login/Login";
import Notification from "./Pages/Notification/Notification";
import Project from "./Pages/Project/Project";
import Report from "./Pages/Report/Report";
import Sensors from "./Pages/Sensors/Sensors";
import SignUp from "./Pages/SignUp/SignUp";
import configVariables from "./util/constants";
import Pay from "./Pages/Pay/Pay";
import SubscriptionDetails from "./Pages/SubscriptionDetails/SubscriptionDetails";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ForgotPassword/ResetPasswrod";
import Types from "./Pages/Admin/Types";
import InactiveAccount from "./Pages/Error/InactiveAccount";
import SubscriptionLayout from "./Components/Layouts/SubscriptionLayout";


function App() {
  useEffect(() => {
    const handleFocusIn = (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        document.body.style.zoom = '1';
      }
    };

    const handleFocusOut = (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        document.body.style.zoom = '';
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('focusout', handleFocusOut);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<SubscriptionLayout />}>
            <Route element={<SidebarLayout />}>
              {/*  owner only routes */}
              <Route element={<RoleAuth allowedRoles={[configVariables.role_owner]} />}>
                <Route path="/manage/business" element={<Business />} />
              </Route>

              {/* owner, manager only routes */}
              <Route element={<RoleAuth allowedRoles={[configVariables.role_owner, configVariables.role_manager]} />}>
                <Route path="/manage/project" element={<Project />} />
              </Route>

              {/* owner, manager, super only routes */}
              <Route element={<RoleAuth allowedRoles={[configVariables.role_owner, configVariables.role_manager, configVariables.role_super]} />}>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/manage/devices" element={<Devices />} />
                <Route path="/manage/devices/sensors/:id" element={<Sensors />} />
                <Route path="/report" element={<Report />} />
                <Route path="/notification" element={<Notification />} />

              </Route>

              {/* admin only routes */}
              <Route element={<RoleAuth allowedRoles={[configVariables.role_admin]} />}>
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/types" element={<Types />} />
              </Route>

              <Route path="/subscription_details" element={<SubscriptionDetails />} />
            </Route>

            <Route path="/account_inactive" element={<InactiveAccount />} />
          </Route>

          {/* common routes */}
          <Route path="/signup/:secret_key" element={<SignUp />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route element={<RoleAuth allowedRoles={[configVariables.role_owner, configVariables.role_admin]} />}>
            <Route path="/pay" element={<Pay />} />
            <Route path="/renew/pay" element={<Pay showLogOut />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;