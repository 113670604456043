import React from 'react'
import logo from '../../Assets/Images/logo.png'
import burger_logo from '../../Assets/Icons/burger.png'
import { useDispatch, useSelector } from 'react-redux';
import { OpenMobileNav, ResetState } from '../../appActions';
import profile_logo from '../../Assets/Icons/man.png'
import { Link, useNavigate } from 'react-router-dom';
import logout_logo from '../../Assets/Icons/logout.png'
import profile2_logo from '../../Assets/Icons/profile.png'
import storageService from '../../util/storageService';
import configVariables from '../../util/constants';
import MenuIcon from '@mui/icons-material/Menu';
import '../../App.css'
import Header from '../../Pages/dashboardheader/header'; 
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Notification from '../../Components/Notification/Notification';


function Navbar() {
  
  const state = useSelector((state) => state);
  const dispatch = useDispatch()
  const nav = useNavigate()
  const isMobile = useMediaQuery('(max-width: 766px)');

  const handleMinimize = () => {
    dispatch(OpenMobileNav(!state.nav_state))
  }

  const logOut = () => {
    storageService.remove(configVariables.USER_ID)
    storageService.remove(configVariables.USER_ROLE);
    storageService.remove(configVariables.USER_JWT_TOKEN);
    dispatch(ResetState())
    nav('/login')
  }
 

  return (
    <div className='navbar-top' >
      <div className="navbar-left">
        <div className="navbar-logo">
          <img src={logo} alt="logo" />
          {/* {!sidebarCollapsed ?
            
            :
            <img className='burger-img' src={burger_logo} alt="" style={{ marginTop: 0 }} onClick={() => { setSidebarCollapsed(false) }} />
          } */}

        </div>
      </div>
      <div className="navbar-right">
        {/* <img className='collapse-burger-img' src={burger_logo} alt="" style={{ marginTop: 0 }} onClick={handleMinimize} /> */}
        <MenuIcon className='collapse-burger-img' style={{ marginTop: 0, cursor: 'pointer' ,color: 'white'}} onClick={handleMinimize} />
        {!isMobile && <Header />}
        <div className="profile-button dropdown">
          <img className='' src={profile_logo} alt="" />
          <div className="dropdown-content navbar-dropdown">
            {window.location.pathname !== '/admin' &&
              <p><AccountCircleIcon /><Link to={'/subscription_details'}>Profile</Link></p>
            }
            <p onClick={logOut}><LogoutIcon />Logout</p>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar