

// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart, registerables } from 'chart.js';

// Chart.register(...registerables);

// function LineChart({ data, graph_det }) {
//   const [chartData, setChartData] = useState({});
//   const [minVal, setMinVal] = useState(0);
//   const [maxVal, setMaxVal] = useState(0);

//   useEffect(() => {
//     if (data) {
//       let min = data[0];
//       min = min ? min[0]?.value : min;
//       const labels = [];
//       let max = min;
//       const datasets = [];

//       data.forEach((dataset, i) => {
//         const dataPoints = dataset.map(d => {
//           const value = parseFloat(d.value);
//           min = parseInt(min) > parseInt(d.value) ? d.value : min;
//           max = Math.max(max, value);
//           if (!labels.includes(d.datetime.split(' ')[0])) {
//             labels.push(d.datetime.split(' ')[0]);
//           }
//           return { x: d.datetime.split(' ')[0], y: d.value };
//         });

//         datasets.push({
//           label: graph_det.name + i,
//           data: dataPoints,
//           borderColor: dataPoints.map(d => {
//             if (d.y <= graph_det.min_threshold) {
//               return graph_det.colors[0];
//             } else if (d.y <= graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             } else if (d.y >= graph_det.max_threshold) {
//               return graph_det.colors[2];
//             }
//             return '#2c6fbb';
//           })[0], // Ensure a single consistent color for the line
//           backgroundColor: 'rgba(255, 255, 255, 0.1)',
//           fill: true,
//           pointBackgroundColor: dataPoints.map(d => {
//             if (d.y <= graph_det.min_threshold) {
//               return graph_det.colors[0];
//             } else if (d.y <= graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             } else if (d.y >= graph_det.max_threshold) {
//               return graph_det.colors[2];
//             }
//             return '#2c6fbb';
//           }),
//           pointBorderColor: dataPoints.map(d => {
//             if (d.y <= graph_det.min_threshold) {
//               return graph_det.colors[0];
//             } else if (d.y <= graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             } else if (d.y >= graph_det.max_threshold) {
//               return graph_det.colors[2];
//             }
//             return '#2c6fbb';
//           }),
//         });
//       });
//       const sortedLabels = [...new Set(labels)].sort((a, b) => {
//         const [hourA, minuteA, secondA] = a.split(':').map(Number);
//         const [hourB, minuteB, secondB] = b.split(':').map(Number);
//         return (hourA - hourB) || (minuteA - minuteB) || (secondA - secondB);
//       });
//       setChartData({
//         labels:sortedLabels, // Ensure unique labels
//         datasets,
//       });

//       setMinVal(min);
//       setMaxVal(max)
//     }
//   }, [data]);

//   const options = {
//     responsive: true,
//     plugins: {
//       title: {
//         display: true,
//         // text: 'Chart.js Line Chart - Cubic interpolation mode',
//       },
//     },
//     interaction: {
//       intersect: false,
//     },
//     scales: {
//       x: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Datetime',
          
//         },
//       },
//       y: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Value',
//         },
//         suggestedMin: minVal-1,
//         suggestedMax: maxVal+1,
//         ticks: {
//           stepSize: 0.5, // Set the difference between tick marks to 1
//         },
//       },
      
      
//     },
//     elements: {
//       line: {
//         tension: 0.4, // smooth lines
//       },
//     },
//   };

//   return (
//     <>
//       {chartData.datasets && (
//         <Line data={chartData} options={options} />
//       )}
//     </>
//   );
// }

// export default LineChart;

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function LineChart({ data, graph_det }) {
  const [chartData, setChartData] = useState({});
  const [minVal, setMinVal] = useState(Number.POSITIVE_INFINITY);
  const [maxVal, setMaxVal] = useState(Number.NEGATIVE_INFINITY);
  

  useEffect(() => {
    if (data) {
      let min = Number.POSITIVE_INFINITY;
      let max = Number.NEGATIVE_INFINITY;
      const labels = [];
      const datasets = [];
  
      data.forEach((dataset, i) => {
        
        // Check if the dataset is in ascending order
        const isAscending = dataset.every((d, idx) => {
          if (idx === 0) return true;
          return new Date(dataset[idx - 1].datetime) <= new Date(d.datetime);
        });
  
        if (!isAscending) {
          dataset.reverse(); // Reverse only if it's not in ascending order
        }
  
        const dataPoints = dataset.map(d => {
          const value = parseFloat(d.value);
          min = Math.min(min, value); // Update min value
          max = Math.max(max, value); // Update max value
          const labelDate = d.datetime.split(' ')[0];
          if (!labels.includes(labelDate)) {
            labels.push(labelDate);
          }
          return { x: labelDate, y: value };
        });
  
        datasets.push({
          label: graph_det.name,
          data: dataPoints,
          borderColor: dataPoints.map(d => {
            if (d.y <= graph_det.min_threshold) {
              return graph_det.colors[0];
            } else if (d.y <= graph_det.mid_threshold) {
              return graph_det.colors[1];
            } else if (d.y >= graph_det.max_threshold) {
              return graph_det.colors[2];
            }
            return '#2c6fbb';
          })[0],
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          fill: true,
          pointBackgroundColor: dataPoints.map(d => {
            if (d.y <= graph_det.min_threshold) {
              return graph_det.colors[0];
            } else if (d.y <= graph_det.mid_threshold) {
              return graph_det.colors[1];
            } else if (d.y >= graph_det.max_threshold) {
              return graph_det.colors[2];
            }
            return '#2c6fbb';
          }),
          pointBorderColor: dataPoints.map(d => {
            if (d.y <= graph_det.min_threshold) {
              return graph_det.colors[0];
            } else if (d.y <= graph_det.mid_threshold) {
              return graph_det.colors[1];
            } else if (d.y >= graph_det.max_threshold) {
              return graph_det.colors[2];
            }
            return '#2c6fbb';
          }),
        });
      });
  
      setChartData({
        labels: [...new Set(labels)],
        datasets,
      });
  
      setMinVal(min);
      setMaxVal(max);
    }
  }, [data, graph_det]);
  

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow the chart to adapt to the container size
    plugins: {
      title: {
        display: true,
        text: 'Line Chart',
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date time',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10, // Limit the number of x-axis ticks to prevent overcrowding
          maxRotation: 0, // Prevent label rotation for better readability
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
        suggestedMin: minVal - 1 ,
        suggestedMax: maxVal + 2 > minVal ? maxVal + 2 : minVal + 1 ,
        ticks: {
          stepSize: 1,
          callback: function(value) {
            return value.toFixed(0); // Ensure tick values are whole numbers
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smooth lines
      },
    },
  };


  return (
    <div style={{ position: 'relative', height: '400px', width: '100%' }}>
      {chartData.datasets && (
        <Line data={chartData} options={options} />
      )}
    </div>
  );
}

export default LineChart;
